import React from "react";
import "./topbar.scss";
import PersonIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";
export const Topbar = ({ menuOpen, setMenuOpen }) => {
  return (
    <div className={"topbar " + (menuOpen && "active")} id="topbar">
      <div className="wrapper">
        <div className="left">
          <Link to="/" className="logo">
            Roshan.
          </Link>
          <div className="itemContainer">
            <Link to="https://github.com/roshan-lama-dev" target="_blank">
              {" "}
              <PersonIcon className="icongithub" />{" "}
            </Link>

            <div className="itemContainer">
              <Link
                to="https://www.linkedin.com/in/roshan-lama/"
                target="_blank"
              >
                <LinkedInIcon className="iconlinkedin" />
              </Link>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line1"></span>
            <span className="line1"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

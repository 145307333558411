import React from "react";
import "./projectDetail.scss";
import { Layout } from "../layout/Layout";
import { useParams } from "react-router-dom";
import bookingImage from "../../assets/booking.jpg";
import spaceX from "../../assets/spaceXClone.jpg";
import library from "../../assets/libraryManagement.jpg";
export const ProjectDetail = () => {
  const data = [
    {
      img: library,
      title: "Library Management System",
      desc: "Library Management System is a full stack web app that allows the user to login, register and keep record of the books.",
      link: "https://library-managment-frontend.vercel.app/",
      tools: ["HTML", "Bootstrap", "MERN Stack"],
    },

    {
      img: "https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80",
      title: "Expenses Tracking Application",
      desc: "Expenses tracking system is a full stack web app that allows the user to login, register and keep record of the expenses. The user can also extract their montly expense and income in pdf format selecting from range of data and time.",
      link: "https://www.rammaheshwari.com/",
    },
    {
      img: bookingImage,
      title: "Hotel Booking Application",
      desc: "LamaBooking is a full stack application crated as a clone of booking.com.",
      desc1: "",
      desc2: "",
      desc3: "",
      link: "https://bookingapplication-5107o4mbf-roshan-lama-dev.vercel.app/",
      tools: ["HTML", "REACT JS", "SASS"],
    },
    {
      img: spaceX,
      title: "SpaceX Clone UI",
      desc: "SpaceX UI clone created using HTML, CSS & JavaScript.",
      link: "https://spacex-clone-three.vercel.app/",
      tools: ["HTML", "CSS", "JAVASCRIPT"],
    },
  ];

  const { index } = useParams();
  const projectIndex = parseInt(index, 10);

  const selectedProject = data[projectIndex];

  return (
    <Layout className="maincontainer">
      <div className="container">
        <div className="projectdetail-title">
          <h1>{selectedProject?.title}</h1>
          <p>{selectedProject?.desc}</p>
          <a className="button" href={selectedProject?.link}>
            Live Link
          </a>
        </div>
      </div>
      <div className="projectMoreDetails">
        <div className="imgContainer">
          <img src={selectedProject?.img} alt={selectedProject?.title} />
        </div>
        <h1>Project Overview</h1>
        <div className="project-overview">
          <p>
            Dopefolio is an Open-Source project which is a simple and clean
            multi-page portfolio website template for developers. I created this
            project for developers to quickly build a good-looking and
            fast-performing multi-page portfolio without having to code their
            portfolio from scratch.
          </p>
        </div>

        <h1>Tools Used</h1>
        <div className="toolsUsed">
          {selectedProject.tools?.map((item, index) => {
            return (
              <div key={index} className="toolDetails">
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

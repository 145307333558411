import React from "react";
import "./testimonial.scss";
import { Link } from "react-router-dom";

export const Testimonial = () => {
  const data = [
    {
      img: "assets/libraryManagement.jpg",
      title: "Library Management System",
      desc: "Library Management System is a full stack web app that allows the user to login, register and keep record of the books.",
      link: "https://library-managment-frontend.vercel.app/",
      tools: ["HTML", "Bootstrap", "MERN Stack"],
    },

    {
      img: "https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80",
      title: "Expenses Tracking Application",
      desc: "Expenses tracking system is a full stack web app that allows the user to login, register and keep record of the expenses. The user can also extract their montly expense and income in pdf format selecting from range of data and time.",
      link: "https://client-expense-tracker.vercel.app/",
    },
    {
      img: "assets/booking.jpg",
      title: "Hotel Booking Application",
      desc: "LamaBooking is a full stack application crated as a clone of booking.com. It allows the user to create their own accout, search for the open rooms in the hotel. And book the hotel rooms for their desired amount of days.",
      desc1: "",
      desc2: "",
      desc3: "",
      link: "https://bookingapplication-5107o4mbf-roshan-lama-dev.vercel.app/",
      tools: ["HTML", "REACT JS", "SASS"],
    },
    {
      img: "assets/spaceXClone.jpg",
      title: "SpaceX Clone UI",
      desc: "SpaceX UI clone created using HTML, CSS & JavaScript. It aims to showcase the ability to create and recreate any UI in pixel perfect format. Also the tech stack such as GRAPHQL is very important, along with TypeScript, headless UI,CMS, different framework for backend developement. The time you took any project lead. It is not that you dont lack skill. What you lack is local experinece and evidence that you have and are capable of working in a team and sloving real world problem.",
      link: "https://spacex-clone-three.vercel.app/",
      tools: ["HTML", "CSS", "JAVASCRIPT"],
    },
    {
      img: "assets/spaceXClone.jpg",
      title: "SpaceX Clone UI",
      desc: "SpaceX UI clone created using HTML, CSS & JavaScript. It aims to showcase the ability to create and recreate any UI in pixel perfect format. Also the tech stack such as GRAPHQL is very important, along with TypeScript, headless UI,CMS, different framework for backend developement. The time you took any project lead. It is not that you dont lack skill. What you lack is local experinece and evidence that you have and are capable of working in a team and sloving real world problem.",
      link: "https://spacex-clone-three.vercel.app/",
      tools: ["HTML", "CSS", "JAVASCRIPT"],
    },
  ];

  return (
    <div className="testimonial" id="testimonial">
      <div className="container">
        <div className="project-title">
          <h1>PROJECT</h1>
          <span className="uderline"></span>
          <p>
            Here you can browse through some of my personal and clients projects
            that I created along with the case studies.
          </p>
        </div>

        <div className="project-content">
          {data.map((item, index) => {
            return (
              <div key={index} className="project-row">
                <div className="project-row-image-container">
                  <img src={item?.img} alt="" />
                </div>
                <div className="project-row-content">
                  <h3>{item?.title}</h3>
                  <p>{item?.desc}</p>
                  <Link
                    to={{
                      pathname: `/project/${index}`,
                    }}
                    className="btn"
                  >
                    Case Study
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import "./work.scss";
export const Work = () => {
  return (
    <div className="aboutme" id="aboutme">
      <div className="container">
        <div className="about-title">
          <h1>ABOUT ME</h1>
          <span className="uderline"></span>
          <p>
            Here you will find more infromation about me, my current skills
            mostly in terms of programming and technology
          </p>
        </div>

        <div className="about-content">
          <div className="about-content-main">
            <h3>More about me</h3>
            <div className="about-content-details">
              <p>
                I am a <strong>Full Stack Web Developer</strong> proficient in
                using <strong>MERN STACK</strong>.{" "}
              </p>
              <p>
                I am a Bachelors in Information Technology graduate from
                Southern Cross University. I along with my friends am currently
                engaged in a start-up that helps Job Seeker apply and manage the
                applied jobs. The name of the start-up is{" "}
                <strong>TalenTrova.</strong>
              </p>
              <p>
                I am a <strong>Full Stack Web Developer</strong> proficient in
                using <strong>MERN STACK</strong>.{" "}
              </p>

              <a href="#contact" className="btn">
                Contact
              </a>
            </div>
          </div>
          <div className="about-content-skills">
            <h3>Skills</h3>
            <div className="skills">
              <div className="skill-list">HTML</div>
              <div className="skill-list">CSS</div>
              <div className="skill-list">Bootstrap</div>
              <div className="skill-list">JavaScript</div>
              <div className="skill-list">React JS</div>
              <div className="skill-list">GIT</div>
              <div className="skill-list">Node JS</div>
              <div className="skill-list">Express</div>
              <div className="skill-list">Mongo DB</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
